import $ from "jquery";
window.$ = window.jQuery = $;
import "slick-carousel";
import "select2";
// import Scrollbar from "smooth-scrollbar";
import AOS from "aos";
import ScrollMagic from "ScrollMagic";
import "./scss/main.scss";
import LazyLoad from "vanilla-lazyload";
import simpleParallax from "simple-parallax-js";
// import Parallax from "parallax-js";

// aos
document.addEventListener("DOMContentLoaded", function () {
  AOS.init();
});

// lazy
let images = document.querySelectorAll(".lazy");
new LazyLoad(images);

// selects
$(document).ready(function () {
  $(".select2").select2();
});

// view?
var isInViewport = function (elem) {
  var bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= -200 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right - 500 <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

function isInView(elem) {
  return $(elem).offset().top - $(window).scrollTop() - 300 < $(elem).height();
}

// courtain
var isSafari1 = window.safari !== undefined;
var ua = window.navigator.userAgent;
var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
var webkit = !!ua.match(/WebKit/i);
var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

if (isSafari1 || iOSSafari) {
  $(".courtain .dot").remove();
} else {
  $(".courtain .dot").addClass("active");
}
document.addEventListener("DOMContentLoaded", function () {
  setTimeout(() => {
    $(".courtain").addClass("remove");
  }, 1000);
  setTimeout(() => {
    $(".courtain").remove();
  }, 1500);
});

// header classes
const header = document.querySelector("header");

if (header) {
  let position = document.documentElement.scrollTop;
  window.onscroll = () => {
    position = document.documentElement.scrollTop;
    if (position > 100) {
      header.classList.add("header--min");
    } else {
      header.classList.remove("header--min");
    }
  };
}

document.addEventListener("DOMContentLoaded", function () {
  let position = document.documentElement.scrollTop;
  if (position > 100) {
    header.classList.add("header--min");
  }
});

// globals
function round(num, decimalPlaces) {
  num = Math.round(num + "e" + decimalPlaces);
  return Number(num + "e" + -decimalPlaces);
}

const speed = (el, speed, duration, timeout) => {
  setTimeout(() => {
    // console.log("speed " + el[0].className + " " + speed);
    $({ countNum: el.html() }).animate(
      { countNum: speed },
      {
        duration: duration,
        easing: "linear",
        step: function () {
          el.html(Math.floor(this.countNum));
        },
      }
    );
  }, timeout);
};

const distance = (el, speed, duration, timeout) => {
  setTimeout(() => {
    // console.log("speed " + el[0].className + " " + speed);
    $({ countNum: el.html() }).animate(
      { countNum: speed },
      {
        duration: duration,
        easing: "linear",
        step: function () {
          el.html(round(this.countNum, 1));
        },
      }
    );
  }, timeout);
};

const reset = (el) => {
  // console.log("reset");
  el.html("0");
};

var controller = new ScrollMagic.Controller();
if (window.width > 991){
  var imagesParallax = document.querySelectorAll(".parallax");
  new simpleParallax(imagesParallax, {
    scale: 1.1,
    maxTransition: 90,
  });
}

// animation fleet
const heroHome = document.querySelectorAll(".hero__home");
var isSafari = window.safari !== undefined;
var ua1 = window.navigator.userAgent;
var iOS1 = !!ua1.match(/iPad/i) || !!ua1.match(/iPhone/i);
var webkit1 = !!ua1.match(/WebKit/i);
var iOSSafari1 = iOS1 && webkit1 && !ua1.match(/CriOS/i);

if (isSafari || iOSSafari1) {
  // console.log(isSafari);
  $(".animation__fleet").remove();

  if (heroHome.length > 0) {
    var safariVideo = $('.safariVideo');
    var safariVideoSrc = safariVideo.data('src');
    safariVideo.attr('src', safariVideoSrc);
    safariVideo.trigger('load');
    safariVideo.trigger('play');
    const heroHomePhone = document.querySelector(".phone");
    const nextDetails = document.querySelector(".next-details");
    const nums = document.querySelector(".has--numbers");

    const sectionDevice = document.querySelectorAll(".detailed-list");
    const device = document.querySelector(".detailed-list-img");

    // const cars = document.querySelectorAll(".car__dot");

    var scene = new ScrollMagic.Scene({
      triggerElement: ".hero__home",
      triggerHook: 0,
      duration: heroHome[0].offsetHeight,
    })
      .on("progress", function () {
        let progress = scene.progress() * 100;
        progress = Math.round(progress);

        heroHomePhone.style.top = 50 - progress / 2 + "%";
        // console.log(progress);
      })
      .addTo(controller);
    // .addIndicators({ name: "pin scene home", colorEnd: "#000" });

    var scene = new ScrollMagic.Scene({
      triggerElement: ".hero__home",
      triggerHook: 0,
      duration: heroHome[0].offsetHeight,
    })
      .on("progress", function () {
        let progress = scene.progress() * 100;
        progress = Math.round(progress);

        heroHomePhone.style.top = 50 - progress / 2 + "%";
        // console.log(progress);
      })
      .addTo(controller);
    // .addIndicators({ name: "pin scene home", colorEnd: "#000" });

    var scene1 = new ScrollMagic.Scene({
      triggerElement: ".detailed-list",
      triggerHook: 1,
      duration: sectionDevice[0].offsetHeight,
    })
      .on("progress", function () {
        let progress = scene1.progress() * 100;
        progress = Math.round(progress);

        device.style.top = 500 - progress * 2 + "px";
      })
      .addTo(controller);
    // .addIndicators({ name: "pin scene device", colorEnd: "#000" });

    var scene2 = new ScrollMagic.Scene({
      triggerElement: ".next-details",
      triggerHook: 0.8,
      duration: nextDetails.offsetHeight,
    });
    scene2.on("enter", function () {
      nextDetails.style.backgroundColor = "#33464d";
    }),
      scene2
        .on("leave", function () {
          nextDetails.style.backgroundColor = "#373f41";
        })
        .addTo(controller);
    // .addIndicators({ name: "pin scene - bggg device", colorEnd: "#ce6d34" });

    $(".next-details--slider").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      fade: true,
      prevArrow: $(".prev1"),
      nextArrow: $(".next1"),
      autoplay: true,
    });

    var scene2 = new ScrollMagic.Scene({
      triggerElement: ".has--numbers",
      triggerHook: 0.9,
      duration: nums.offsetHeight,
      reverse: false,
    });

    scene2
      .on("enter", function () {
        $(".count-num").each(function () {
          $(this)
            .prop("Counter", 0)
            .animate(
              {
                Counter: $(this).text(),
              },
              {
                duration: 4000,
                easing: "linear",
                step: function (now) {
                  $(this).text(Math.ceil(now).toLocaleString());
                },
              }
            );
        });
      })
      .addTo(controller);
    // .addIndicators({ name: "pin scene - nums device", colorEnd: "#ce6d34" });
  }
} else {
  $(".isSafariVideo").remove();
  if (heroHome.length > 0) {
    const heroHomePhone = document.querySelector(".phone");
    const nextDetails = document.querySelector(".next-details");
    const nums = document.querySelector(".has--numbers");

    const sectionDevice = document.querySelectorAll(".detailed-list");
    const device = document.querySelector(".detailed-list-img");

    const car1 = document.querySelector(".car__dot--one");
    const car2 = document.querySelector(".car__dot--two");
    const car3 = document.querySelector(".car__dot--three");
    const car4 = document.querySelector(".car__dot--four");
    // const cars = document.querySelectorAll(".car__dot");

    document.addEventListener("DOMContentLoaded", function () {
      $(".phone").addClass("active");

      // [].forEach.call(cars, function (el) {
      //   el.addEventListener("click", function () {
      //     el.classList.toggle("marked");
      //   });
      // });

      const highlightCars = () => {
        setTimeout(() => {
          car1.classList.remove("marked");
          car2.classList.add("marked");
          car3.classList.remove("marked");
          car4.classList.remove("marked");
        }, 1000);
        setTimeout(() => {
          car1.classList.add("marked");
          car2.classList.remove("marked");
          car3.classList.remove("marked");
          car4.classList.remove("marked");
        }, 4000);
        setTimeout(() => {
          car1.classList.remove("marked");
          car2.classList.remove("marked");
          car3.classList.add("marked");
          car4.classList.remove("marked");
        }, 8000);
        setTimeout(() => {
          car1.classList.remove("marked");
          car2.classList.remove("marked");
          car3.classList.remove("marked");
          car4.classList.add("marked");
        }, 12000);
      };

      setInterval(() => {
        highlightCars();
      }, 15000);
      highlightCars();

      setTimeout(() => {
        car1.classList.add("move");
        const elementSpeed = $(".car__one--speed");
        const elementDistance = $(".car__one--distance");

        const speedAnimation = () => {
          speed(elementSpeed, 71, 12000, 0);
          speed(elementSpeed, 22, 20000, 12000);
          speed(elementSpeed, 18, 7000, 32000);
          speed(elementSpeed, 65, 31000, 39000);
          speed(elementSpeed, 0, 17000, 70000);
          speed(elementSpeed, 67, 33000, 87000);
        };
        speedAnimation();
        setInterval(() => {
          // console.log("interval");
          speedAnimation();
        }, 120000);

        const distanceAnimation = () => {
          distance(elementDistance, 1.8, 12000, 0);
          distance(elementDistance, 2.5, 20000, 12000);
          distance(elementDistance, 2.8, 7000, 32000);
          distance(elementDistance, 4.9, 31000, 39000);
          distance(elementDistance, 5.7, 17000, 70000);
          distance(elementDistance, 6.7, 33000, 87000);
        };
        distanceAnimation();
        setInterval(() => {
          // console.log("interval distance");
          distanceAnimation();

          reset(elementDistance);
        }, 120000);
      }, 0);
      setTimeout(() => {
        car2.classList.add("move");
        const elementSpeed = $(".car__two--speed");
        const elementDistance = $(".car__two--distance");

        const speedAnimation = () => {
          speed(elementSpeed, 47, 15000, 0);
          speed(elementSpeed, 0, 11000, 15000);
          speed(elementSpeed, 54, 24000, 26000);
        };
        speedAnimation();
        setInterval(() => {
          // console.log("interval");
          speedAnimation();
        }, 50000);

        const distanceAnimation = () => {
          distance(elementDistance, 1.7, 15000, 0);
          distance(elementDistance, 2.3, 11000, 15000);
          distance(elementDistance, 3.9, 24000, 26000);
        };
        distanceAnimation();
        setInterval(() => {
          // console.log("interval distance");
          distanceAnimation();

          reset(elementDistance);
        }, 50000);
      }, 0);
      setTimeout(() => {
        car3.classList.add("move");
        const elementSpeed = $(".car__three--speed");
        const elementDistance = $(".car__three--distance");

        const speedAnimation = () => {
          speed(elementSpeed, 19, 14000, 0);
          speed(elementSpeed, 31, 16000, 14000);
          speed(elementSpeed, 36, 40000, 30000);
          speed(elementSpeed, 0, 50000, 70000);
        };
        speedAnimation();
        setInterval(() => {
          // console.log("interval");
          speedAnimation();
        }, 120000);

        const distanceAnimation = () => {
          distance(elementDistance, 0.8, 14000, 0);
          distance(elementDistance, 1.8, 16000, 14000);
          distance(elementDistance, 3.2, 40000, 30000);
          distance(elementDistance, 4.1, 50000, 70000);
        };
        distanceAnimation();
        setInterval(() => {
          // console.log("interval distance");
          distanceAnimation();

          reset(elementDistance);
        }, 120000);
      }, 0);
      setTimeout(() => {
        car4.classList.add("move");
        const elementSpeed = $(".car__four--speed");
        const elementDistance = $(".car__four--distance");

        const speedAnimation = () => {
          speed(elementSpeed, 56, 10000, 0);
          speed(elementSpeed, 0, 22000, 10000);
          speed(elementSpeed, 54, 32000, 32000);
          speed(elementSpeed, 49, 16000, 64000);
        };
        speedAnimation();
        setInterval(() => {
          // console.log("interval");
          speedAnimation();
        }, 70000);

        const distanceAnimation = () => {
          distance(elementDistance, 1.5, 10000, 0);
          distance(elementDistance, 2.3, 22000, 10000);
          distance(elementDistance, 3.6, 32000, 32000);
          distance(elementDistance, 4.8, 16000, 64000);
        };
        distanceAnimation();
        setInterval(() => {
          // console.log("interval distance");
          distanceAnimation();

          reset(elementDistance);
        }, 70000);
      }, 0);
    });

    var scene = new ScrollMagic.Scene({
      triggerElement: ".hero__home",
      triggerHook: 0,
      duration: heroHome[0].offsetHeight,
    })
      .on("progress", function () {
        let progress = scene.progress() * 100;
        progress = Math.round(progress);

        heroHomePhone.style.top = 50 - progress / 2 + "%";
        // console.log(progress);
      })
      .addTo(controller);
    // .addIndicators({ name: "pin scene home", colorEnd: "#000" });

    var scene1 = new ScrollMagic.Scene({
      triggerElement: ".detailed-list",
      triggerHook: 1,
      duration: sectionDevice[0].offsetHeight,
    })
      .on("progress", function () {
        let progress = scene1.progress() * 100;
        progress = Math.round(progress);

        device.style.top = 500 - progress * 2 + "px";
      })
      .addTo(controller);
    // .addIndicators({ name: "pin scene device", colorEnd: "#000" });

    var scene2 = new ScrollMagic.Scene({
      triggerElement: ".next-details",
      triggerHook: 0.8,
      duration: nextDetails.offsetHeight,
    });
    scene2.on("enter", function () {
      nextDetails.style.backgroundColor = "#33464d";
    }),
      scene2
        .on("leave", function () {
          nextDetails.style.backgroundColor = "#373f41";
        })
        .addTo(controller);
    // .addIndicators({ name: "pin scene - bggg device", colorEnd: "#ce6d34" });

    $(".next-details--slider").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      fade: true,
      prevArrow: $(".prev1"),
      nextArrow: $(".next1"),
      autoplay: true,
    });

    var scene2 = new ScrollMagic.Scene({
      triggerElement: ".has--numbers",
      triggerHook: 0.9,
      duration: nums.offsetHeight,
      reverse: false,
    });

    scene2
      .on("enter", function () {
        $(".count-num").each(function () {
          $(this)
            .prop("Counter", 0)
            .animate(
              {
                Counter: $(this).text(),
              },
              {
                duration: 4000,
                easing: "linear",
                step: function (now) {
                  $(this).text(Math.ceil(now).toLocaleString());
                },
              }
            );
        });
      })
      .addTo(controller);
    // .addIndicators({ name: "pin scene - nums device", colorEnd: "#ce6d34" });
  }
}

// platform slider
$(".platform--slider").slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  fade: true,
  prevArrow: $(".prev--up"),
  nextArrow: $(".next--down"),
  autoplay: true,
});

// call for download
const call = document.querySelector(".call-for-download");
if (!call == false) {
  var sceneCall = new ScrollMagic.Scene({
    triggerElement: ".call-for-download",
    triggerHook: 0.8,
    duration: call.offsetHeight,
    reverse: false,
  });
  sceneCall
    .on("enter", function () {
      call.style.backgroundColor = "#929799";
    })
    .addTo(controller);
  // .addIndicators({ name: "pin scene - call", colorEnd: "#ce6d34" });
}

// call for order
const sectionCall = document.querySelectorAll(".call-for-order");
const device2 = document.querySelector(".call-img");

if (sectionCall.length > 0) {
  var scene3 = new ScrollMagic.Scene({
    triggerElement: ".call-for-order",
    triggerHook: 0.8,
    duration: sectionCall[0].offsetHeight,
  })
    .on("progress", function () {
      let progress = scene3.progress() * 100;
      progress = Math.round(progress);

      device2.style.bottom = 25 + progress / 3 + "px";
    })
    .on("enter", function () {
      sectionCall[0].style.backgroundColor = "#33464d";
    })
    .addTo(controller);
  // .addIndicators({ name: "pin scene device", colorEnd: "#000" });
}

// quantity add to cart
const inputNumber = $(".input__quantity");

inputNumber.each(function (el) {
  $(this).change(function () {
    if ($(this).val() < 1) {
      $(this)
        .closest(".quantity__add-to-cart")
        .find(".cart__add")
        .addClass("disabled");
      $(this).closest(".quantity__add-to-cart").find(".cart__add");
    } else {
      $(this)
        .closest(".quantity__add-to-cart")
        .find(".cart__add")
        .removeClass("disabled");
      $(this).closest(".quantity__add-to-cart").find(".cart__add");
    }
  });
});

$(".qnt__decrement").click(function () {
  let input = $(this)
    .closest(".input__quantity--wrapper")
    .find(".input__quantity");
  if (input.val() > 0) {
    input.get(0).value--;
  }
  // console.log(input.val());
  if (input.val() <= 0) {
    $(this)
      .closest(".quantity__add-to-cart")
      .find(".cart__add")
      .addClass("disabled");
  } else {
    $(this)
      .closest(".quantity__add-to-cart")
      .find(".cart__add")
      .removeClass("disabled");
  }
});

$(".qnt__increment").click(function () {
  let input = $(this)
    .closest(".input__quantity--wrapper")
    .find(".input__quantity");
  input.get(0).value++;
  if (input.val() <= 0) {
    $(this)
      .closest(".quantity__add-to-cart")
      .find(".cart__add")
      .addClass("disabled");
  } else {
    $(this)
      .closest(".quantity__add-to-cart")
      .find(".cart__add")
      .removeClass("disabled");
  }
});

// popup order
const reload = () => {
  const isCart = $(".woocommerce-cart");
  if (isCart.length > 0) {
    location.reload();
  }
};

$(".popup__close").click(function (e) {
  e.preventDefault();
  $(".order__popup").addClass("hidden");
  setTimeout(() => {
    $(".order__popup").css("display", "none");
    reload();
  }, 400);
});

$(".order__link").click(function (e) {
  e.preventDefault();
  $(".order__popup").css("display", "block");
  setTimeout(() => {
    $(".order__popup").removeClass("hidden");
  }, 400);
});

document.addEventListener("click", ({ target }) => {
  if (!target.closest(".order__popup--inner, .order__link")) {
    $(".order__popup").addClass("hidden");
    // console.log(target.classList.value);
    if (target.classList.value == "order__popup hidden") {
      reload();
    }
    setTimeout(() => {
      $(".order__popup").css("display", "none");
    }, 400);
  }
});

// checkout forward
$(".checkout__next").click(function (e) {
  e.preventDefault();
  $(this).addClass("loading-blink");
  $("#place_order").click();
});

$("#main-agree, #reg-agree").on("change", function () {
  if ($("#main-agree").is(":checked") && $("#reg-agree").is(":checked")) {
    $(".checkout__next").removeClass("disabled");
  } else {
    $(".checkout__next").addClass("disabled");
  }
});

$("#reg-agree").on("change", function () {
  if ($("#reg-agree").is(":checked")) {
    $("#terms").attr("checked", true);
  } else {
    $("#terms").attr("checked", false);
  }
});

const regsUrl = $(".woocommerce-terms-and-conditions-link").attr("href");
$(".regs").attr("href", regsUrl);

$(".checkout__next-wrapper").on("mouseenter", function () {
  console.log("on");
  if ($(".checkout__next").hasClass("disabled")) {
    if (!$("#main-agree").is(":checked")) {
      $("#main-agree").addClass("warn");
    }
    if (!$("#reg-agree").is(":checked")) {
      $("#reg-agree").addClass("warn");
    }
  }
});

$(".checkout__next-wrapper").on("mouseleave", function () {
  console.log("off");
  $("#main-agree").removeClass("warn");
  $("#reg-agree").removeClass("warn");
});

let shippingMethods = $("ul#shipping_method");
// console.log(shippingMethods);
// $("#shipping__methods--wrapp").append(shippingMethods);

// benefits for who
const checkVisible = function () {
  let lengths = $(".for--who-feed-item:visible").length;
  if (lengths < 7) {
    $(".feed--more").fadeOut();
  } else {
    $(".feed--more").fadeIn();
    $(".feed-item-hidden").removeClass("active");
  }
};

$(".branches").click(function (e) {
  e.preventDefault();
  $(".branches").removeClass("active");
  $(this).addClass("active");

  let currId = $(this).attr("id");

  if (currId == "all_branches") {
    $(".for--who-feed-item").fadeIn(800);
    $(".for--who-feed-item").removeClass("feed-item-hidden");
  } else {
    $(".for--who-feed-item").fadeOut(0);
    $(".item-" + currId).fadeIn(800);
    $(".item-" + currId).removeClass("feed-item-hidden");
    $(".feed--more").fadeOut();
  }
});

$(".feed--for").click(function (e) {
  e.preventDefault();
  $(".feed-item-hidden").addClass("active");
  $(this).fadeOut();
});

// Story
$(".dot").click(function () {
  $(".dot").removeClass("active");
  $(".about--story-item").removeClass("active");
  $(this).addClass("active");

  let currId = $(this).attr("id");

  $("#story-" + currId).addClass("active");
});

// article read more
$(".read__more").click(function (e) {
  e.preventDefault();
  $(this).fadeOut(100);
  $(".content__shortener").addClass("content__full");
});

// help movies
$(".iframe__nav--item").click(function () {
  $(".iframe__nav--item").removeClass("active");
  $(this).addClass("active");

  let currId = $(this).attr("id");

  $(".iframe__wrapper").removeClass("active");
  $("#iframe-" + currId).addClass("active");
});

// faq
$(".faq__list--item").click(function () {
  $(this).toggleClass("active");
});

$(".faq__sort").change(function () {
  let currVal = $(this).val();
  // console.log(currVal);
  let currLenght = $(".i-" + currVal).length;
  if (currVal == "all__cat") {
    $(".faq__list--item").fadeIn(500);
    $(".faq__list--item").removeClass("faq__list--item-hidden");
    $(".feed--faq").fadeOut(500);
  } else {
    $(".faq__list--item").removeClass("faq__list--item-hidden");
    $(".faq__list--item").fadeOut(200);
    $(".i-" + currVal).fadeIn(500);
    $(".feed--faq").fadeOut(500);
    // if (currLenght > 5) {
    //   $(".feed--faq").fadeIn(700);
    //   $(".faq__list--item-hidden").removeClass("visible");
    // } else {
    //   $(".feed--faq").fadeOut(700);
    // }
  }
});

$(".feed--faq").click(function (e) {
  e.preventDefault();
  $(".faq__list--item").removeClass("faq__list--item-hidden");
  $(this).fadeOut();
});

// call for order slider
$(".call__slider").slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  fade: true,
  prevArrow: $(".prev2"),
  nextArrow: $(".next2"),
  autoplay: true,
});

// nav
$(".nav__toggler").click(function () {
  $(this).toggleClass("active");
  $(".mobile__menu").toggleClass("active");
});

// iframes handle
$(".start--iframe").click(function () {
  $(this).fadeOut();
});

// Animations
const heroHome2 = document.querySelector(".hero__home");
if (heroHome2) {
  var scene = new ScrollMagic.Scene({
    triggerElement: ".hero__home",
    triggerHook: 0,
    duration: heroHome[0].offsetHeight,
  })
    .on("progress", function () {
      let progress = scene.progress() * 100;
      progress = Math.round(progress);

      // console.log(progress);
    })
    .addTo(controller);
  // .addIndicators({ name: "pin scene home", colorEnd: "#000" });
}

const heroHome1 = document.querySelector(".hero__home");
if (heroHome1) {
  const heroHometext1 = document.querySelector(".hero__home--text");

  var sceneHome0 = new ScrollMagic.Scene({
    triggerElement: ".hero__home",
    triggerHook: 0,
    duration: heroHome[0].offsetHeight,
  })
    .on("progress", function () {
      let progress = scene.progress() * 100;
      progress = Math.round(progress);

      heroHometext1.style.transform =
        "translate(" + -50 + "%," + (-50 - progress / 2) + "%)";

      // console.log(progress);
    })
    .addTo(controller);
  // .addIndicators({ name: "pin scene home", colorEnd: "#000" });
}

// shipping methods
const isShipping = document.querySelector("#myshipping");
if (isShipping) {
  const getAllMethods = document.querySelectorAll("#myshipping ul li input");
  const getAllMethodsInWoo = document.querySelectorAll(
    "#shipping_method li input"
  );

  getAllMethodsInWoo.forEach(function (item, i) {
    if (item.checked) {
      getAllMethods.forEach(function (item, i) {
        item.checked = true;
      });
    }
  });
}

// checkout docs change by priv - company
document.addEventListener("DOMContentLoaded", function () {
  const inputCompany = document.querySelectorAll(".input-radio");
  if (inputCompany.length > 0) {
    const agrCo = document.querySelector(".agreeCompany");
    const agrPriv = document.querySelector(".agreePriv");
    const regLink = document.querySelector(".regs");

    inputCompany.forEach((el) => {
      if (el.checked) {
        if (el.value == "Firma") {
          agrPriv.style.display = "none";
          regLink.href = "/regulamin/";
        }
        if (el.value == "Osoba prywatna") {
          agrCo.style.display = "none";
          regLink.href = "/regulamin-osoba-prywatna/";
        }
      }

      el.addEventListener("click", function () {
        if (el.value == "Firma") {
          agrCo.style.display = "block";
          agrPriv.style.display = "none";
          regLink.href = "/regulamin/";
        } else {
          agrPriv.style.display = "block";
          agrCo.style.display = "none";
          regLink.href = "/regulamin-osoba-prywatna/";
        }
      });
    });
  }
});
